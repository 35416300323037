import { TError } from 'models';
import { combineErrors, translate } from 'utils';
import React from 'react';
import { Error, ErrorCode, ErrorMessage, ErrorSimple, ErrorSimpleCode, ErrorSimpleMessage } from './styles';

export const showErrors = (fieldsArray: string[], errors: TError[]) => {
  const combinedErrors = combineErrors(fieldsArray, errors);

  if (combinedErrors?.length) {
    return renderErrors(combinedErrors);
  }
};

export const renderErrors = (items: {code: string, message: string, replace?: string[]}[]) => items.map((item, index) => {

  const translatedCode = item.code ? translate({ key: `error.${item.code}`, replace: item.replace, fallback: item.code }) : item.code;
  const message = item.code !== translatedCode ? translatedCode : item.message;

  return (
    <Error key={index} className="js-common-error">
      {(item.code && (item.code === translatedCode)) ? (
        <ErrorCode>{item.code}{item.message ? ':' : null}&nbsp;</ErrorCode>
      ) : null}
      <ErrorMessage>{message}</ErrorMessage>
    </Error>
  );
});

export const renderSimpleErrors = (errors: TError[]) => (
  <>
    {errors.map((error, index) => (
      <ErrorSimple key={index} className="js-common-error">
        {error.code ? <ErrorSimpleCode>{error.code}:&nbsp;</ErrorSimpleCode> : null}
        <ErrorSimpleMessage>{error.message}</ErrorSimpleMessage>
      </ErrorSimple>
    ))}
  </>
);
