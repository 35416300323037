import React from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { ProvideAuth } from 'context/Auth';
import { ProvideScheme } from 'context/Schema';
import { ProvideApp } from 'context/App';

import Routes from './routes';
import Misc from './Components/Misc';
import { isIframe } from 'utils';
import { ErrorBoundary } from '@sentry/react';
import { Loader } from '../styles/common';
import 'init';

const INTERCOM_APP_ID = window._env_.INTERCOM_APP_ID;
export const INTERCOM_SHOULD_INITIALIZE = window._env_.STAGE !== 'local' && !isIframe();

const App = () => (
  <ProvideAuth>
    <ProvideScheme>
      <ProvideApp>
        <IntercomProvider
          autoBoot
          shouldInitialize={INTERCOM_SHOULD_INITIALIZE}
          appId={INTERCOM_APP_ID}
        >
          <>
            <ErrorBoundary fallback={<Loader />}>
              <Routes />
              <Misc />
            </ErrorBoundary>
          </>
        </IntercomProvider>
      </ProvideApp>
    </ProvideScheme>
  </ProvideAuth>
);

export default App;
