import {
  errorNotification,
  IGDPRAgreement,
  preflightAPICall,
  processAPIError,
  processGDPRAgreementsFromSchema,
  translate
} from 'utils';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultIcon, Loader } from 'styles/common';
import { ButtonInner } from 'app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';
import axios, { AxiosError } from 'axios';
import apiConfig, { DEFAULT_LOCALE, GDPR_DOCUMENT_TYPE_ID } from 'config/api';
import { useScheme } from 'context/Schema';
import { JSONSchemaProp, TError, TFetchStatus } from 'models';
import { useApp } from 'context/App';
import { getFile } from 'utils/getFile';
import { getEmailTemplate, setDialogProps } from 'app/Components/EmailPreview';
import { useOutsideClick } from 'hooks';
import { Checkbox, TooltipLarge } from '@insly/qmt-reactjs-ui-lib';
import {
  AgreementIcon,
  AgreementNameCol,
  EditStatusIcon,
  AgreementNameTitle,
  StatusCol,
  StatusTitle,
  GDPRControlsButton,
  GDPRControlsIcon,
  GDPRControlsRow,
  GDPRTable,
  GDPRTitle,
  StatusList,
  StatusListItem
} from 'app/Pages/Clients/profile/body/GDPR/styles';
import { renderErrors } from 'app/Components/common/Error';
import { ColData } from 'app/Pages/common/List/styles';
import { TUserData, useAuth } from 'context/Auth';
import {
  DATA_PROCESSING_GDPR_AGREEMENT,
  EMAIL_GDPR_AGREEMENT,
  MARKETING_GDPR_AGREEMENT,
  SMS_PHONE_GDPR_AGREEMENT
} from './consts';

type TGDPR = {
  profileId: string,
  handleChange?: (value: boolean) => void,
  readonly?: boolean,
};

export type TAgreement = {
  id: string,
  tag: string,
  status: number,
  description?: string,
  checked?: boolean,
  details?: Record<string, boolean>,
  updated_at?: string,
};

type TCustomerAgreementFromSchemaPostObject = {
  tag: string,
  status: number,
  details?: Record<string, boolean>,
};

type TCustomerAgreementPostObject = Record<string, {status: number}>;

const STATUS_ICONS: Record<number, string> = {
  0: 'undefined',
  1: 'pending',
  2: 'pending',
  3: 'accepted',
  4: 'unknown',
  5: 'declined',
};

const STATUSES_FOR_UPDATE = [4, 5];
const ACCEPTED_STATUSES = [3, 4];
const AGREEMENTS_TO_BE_DISABLED = [EMAIL_GDPR_AGREEMENT, SMS_PHONE_GDPR_AGREEMENT];
const GDPR_ERROR_CODE = 'GDPR00002';

export const CUSTOMER_GDPR_URL = `${apiConfig.GDPR}/group/gdpr/ref/%CUSTOMER_ID%`;

export const GDPR = ({ profileId, handleChange, readonly }: TGDPR) => {
  const [agreements, setAgreements] = useState<TAgreement[]>([]);
  const [dataFetchStatus, setDataFetchStatus] = useState<TFetchStatus>('loading');
  const [tag, setTag] = useState('');
  const [noResultsText, setNoResultsText] = useState('');

  const { GDPRSchema, GDPRSchemaErrors, GDPRSchemaFetchStatus, getGDPRSchema } = useScheme();
  const { showNotification, showDialog } = useApp();
  const { user } = useAuth();

  const statusListRef = useRef<HTMLUListElement>(null);
  const isOutside = useOutsideClick(statusListRef);

  useEffect(() => {
    if (getGDPRSchema && (!GDPRSchemaFetchStatus || GDPRSchemaFetchStatus === 'failed')) {
      getGDPRSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (GDPRSchemaFetchStatus === 'success') {
      getAgreements();
    }
  }, [GDPRSchema, profileId]);

  useEffect(() => {
    if (isOutside) {
      setTag('');
    }
  }, [isOutside]);

  const toggleTag = (dataTag: string) => {
    if (tag === dataTag) {
      setTag('');
    } else {
      setTag(dataTag);
    }
  };

  const onAgreementChange = (key: string, value: boolean) => {
    if (agreements?.length) {
      const agreementIndex = agreements.findIndex(agreement => agreement.tag === key);

      if (agreementIndex > -1) {
        const _agreements = [...agreements];
        _agreements[agreementIndex].checked = value;
        const dataProcessingAgreementIndex = _agreements.findIndex(agreement => agreement.tag === DATA_PROCESSING_GDPR_AGREEMENT);
        const marketingAgreementIndex = _agreements.findIndex(agreement => agreement.tag === MARKETING_GDPR_AGREEMENT);

        if (key === MARKETING_GDPR_AGREEMENT) {
          if (marketingAgreementIndex > -1 && !_agreements[marketingAgreementIndex].checked) {
            const emailAgreementIndex = _agreements.findIndex(agreement => agreement.tag === EMAIL_GDPR_AGREEMENT);
            const phoneSmsAgreementIndex = _agreements.findIndex(agreement => agreement.tag === SMS_PHONE_GDPR_AGREEMENT);

            if (emailAgreementIndex > -1 && phoneSmsAgreementIndex > -1) {
              _agreements[emailAgreementIndex].checked = false;
              _agreements[phoneSmsAgreementIndex].checked = false;
            }
          }
        }

        if (key === EMAIL_GDPR_AGREEMENT || key === SMS_PHONE_GDPR_AGREEMENT) {
          if (marketingAgreementIndex > -1 && ACCEPTED_STATUSES.includes(_agreements[marketingAgreementIndex].status)) {
            _agreements[marketingAgreementIndex].checked = true;
          }
        }

        if (_agreements.some(agreement => agreement.checked) && dataProcessingAgreementIndex > -1) {
          _agreements[dataProcessingAgreementIndex].checked = true;
        }

        setAgreements(_agreements);
        handleChange && handleChange(agreements.some(agreement => agreement.checked));
      }
    }
  };

  const onGetListError = (error: AxiosError) => {
    setNoResultsText(() => processAPIError(error) as string);
    setDataFetchStatus('success');
  };

  const onCommonError = (error: AxiosError) => {
    errorNotification(error, showNotification, false, undefined, undefined, true);
    setDataFetchStatus('success');
  };

  const processAgreements = (agreementsFromDB: TAgreement[]) => {
    const agreementsFromSchema = processGDPRAgreementsFromSchema(GDPRSchema);

    agreementsFromDB.forEach(agreementFromDB => {
      const agreementIndex = agreementsFromSchema?.findIndex(agreementFromSchema => agreementFromSchema.tag === agreementFromDB.tag);

      if (agreementIndex > -1) {
        agreementsFromSchema[agreementIndex].id = agreementFromDB.id;
        agreementsFromSchema[agreementIndex].status = agreementFromDB.status;
        agreementsFromSchema[agreementIndex].updated_at = agreementFromDB.updated_at;
      }
    });

    return agreementsFromSchema;
  };

  const getAgreements = () => {
    setDataFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(CUSTOMER_GDPR_URL.replace('%CUSTOMER_ID%', profileId)).then(response => {
        setAgreements(processAgreements(response.data.agreements));
        setDataFetchStatus('success');
      }).catch((error) => {
        if (error.response?.data.errors[0].code === GDPR_ERROR_CODE) {
          setAgreements(processGDPRAgreementsFromSchema(GDPRSchema));
          setDataFetchStatus('success');
        } else {
          onGetListError(error);
        }
      });
    });
  };

  const updateAgreement = (status: number, id?: string, tag?: string) => {
    setDataFetchStatus('loading');

    if (id) {
      preflightAPICall(() => {
        axios.patch(`${apiConfig.GDPR}/${id}`, {
          status
        }).then(response => {
          const _agreements = [...agreements];

          const agreementIndex = _agreements.findIndex(item => item.id === id);

          if (agreementIndex > -1) {
            _agreements[agreementIndex].status = response.data.status;
            _agreements[agreementIndex].updated_at = response.data.updated_at;
          }

          setAgreements(_agreements);
          setDataFetchStatus('success');
        }).catch(onCommonError);
      });
    } else {
      const _agreements = [...agreements];
      const agreementIndex = _agreements.findIndex(agreement => agreement.tag === tag);

      if (agreementIndex > -1) {
        _agreements[agreementIndex].status = status;
        submitAgreements(profileId, GDPRSchema.path as string, _agreements, onCommonError);
      }
    }
  };

  const printAgreements = () => {
    submitAgreements(profileId, GDPRSchema.path as string, agreements, onCommonError, () => {
      preflightAPICall(() => {
        getFile({
          url: `${CUSTOMER_GDPR_URL.replace('%CUSTOMER_ID%', profileId)}/file/download`,
          fileName: 'Agreements',
          onError: onCommonError,
          onSuccess: getAgreements
        });
      });
    });
  };

  const prepareAgreementsFromSchemaBeforeSend = (agreements: TAgreement[]) => agreements.map(agreement => {
    let processedAgreement: TCustomerAgreementFromSchemaPostObject = {
      tag: agreement.tag,
      status: STATUSES_FOR_UPDATE.includes(agreement.status) ? agreement.status : (agreement.checked ? 1 : 2)
    };

    if (agreement.details) {
      processedAgreement.details = {};

      Object.keys(agreement.details).forEach(detail => {
        // @ts-ignore
        processedAgreement.details[detail] = !!agreement.details?.[detail as keyof TDetails]?.checked;
      });
    }

    return processedAgreement;
  });

  const prepareAgreementsBeforeSend = (agreements: TAgreement[]) => {
    let processedAgreement = {} as TCustomerAgreementPostObject;

    agreements.forEach(agreement => {
      processedAgreement[agreement.id as keyof TCustomerAgreementPostObject] = {
        status: agreement.checked ? 1 : 2
      };
    });

    return processedAgreement;
  };

  const submitAgreements = (
    customerId: string,
    schemaPath: string,
    agreements: TAgreement[],
    onError?: (error: AxiosError) => void,
    onSuccess?: () => void,
  ) => {
    setDataFetchStatus('loading');

    const processResponse = () => {
      handleChange && handleChange(false);
      onSuccess ? onSuccess() : setDataFetchStatus('success');
    };

    if (agreements.some(agreement => agreement.id)) {
      preflightAPICall(() => {
        axios.patch(CUSTOMER_GDPR_URL.replace('%CUSTOMER_ID%', profileId), {
          agreements: prepareAgreementsBeforeSend(agreements)
        }).then(() => {
          processResponse();
        }).catch(onError);
      });
    } else {
      preflightAPICall(() => {
        axios.post(`${apiConfig.GDPR}`, {
          schema_path: schemaPath,
          type_tag: 'gdpr',
          ref_id: customerId,
          agreements: prepareAgreementsFromSchemaBeforeSend(agreements)
        }).then((response) => {
          setAgreements(processAgreements(response.data.agreements));
          processResponse();
        }).catch(onError);
      });
    }
  };

  const sendEmail = () => {
    submitAgreements(profileId, GDPRSchema.path as string, agreements, onCommonError, () => {
      getEmailTemplate(`${CUSTOMER_GDPR_URL.replace('%CUSTOMER_ID%', profileId)}`, (response) => {
        showDialog(setDialogProps({
          isEditEnabled: user?.props.gdpr_email_edit_content_preview || false,
          customerId: profileId,
          emailTemplate: response.data.template.content_html,
          typeId: GDPR_DOCUMENT_TYPE_ID,
          sendEmailUrl: `${CUSTOMER_GDPR_URL.replace('%CUSTOMER_ID%', profileId)}/email/send`,
          onSuccess: getAgreements,
          successMessage: translate({ key: 'gdpr.notifications.email_send_success' }),
          onDecline: () => setDataFetchStatus('success'),
          docs: response.data.template.documents || []
        }));
      }, onCommonError);
    });
  };

  const shouldDisableAgreement = (agreementTag: string) => {
    if (AGREEMENTS_TO_BE_DISABLED.includes(agreementTag)) {
      const marketingAgreement = agreements.find(agreement => agreement.tag === MARKETING_GDPR_AGREEMENT);
      if (marketingAgreement) {
        return !marketingAgreement?.checked && !ACCEPTED_STATUSES.includes(marketingAgreement.status);
      }
    }
  };

  const filterStatusesForUpdate = (statuses: number[]) => {
    const marketingAgreement = agreements.find(agreement => agreement.tag === MARKETING_GDPR_AGREEMENT);

    if (marketingAgreement && !ACCEPTED_STATUSES.includes(marketingAgreement.status)) {
      const statusToRemoveIndex = STATUSES_FOR_UPDATE.findIndex(status => status === 4);

      if (statusToRemoveIndex > -1) {
        statuses.splice(statusToRemoveIndex, 1);
      }
    }

    return statuses;
  };

  const renderAgreementName = (data: TAgreement, user: TUserData) => (
    <AgreementNameCol>
      <Checkbox
        disabled={shouldDisableAgreement(data.tag) || readonly}
        name={data.tag}
        checked={data.checked}
        handleChange={(key, value) => onAgreementChange(key, value)}
      />
      {/*{data.checked && data.details ? (*/}
      {/*  <AgreementItemsWrapper>*/}
      {/*    <Checkbox*/}
      {/*      key="email"*/}
      {/*      name="email"*/}
      {/*      label="email"*/}
      {/*      checked={data.details.email}*/}
      {/*      handleChange={(key, value) => console.log(EMAIL_GDPR_AGREEMENT, value)}*/}
      {/*    />*/}
      {/*    <Checkbox*/}
      {/*      key="phone"*/}
      {/*      name="phone"*/}
      {/*      label="phone"*/}
      {/*      checked={data.details.phone}*/}
      {/*      handleChange={(key, value) => console.log('phone', value)}*/}
      {/*    />*/}
      {/*    <Checkbox*/}
      {/*      key="sms"*/}
      {/*      name="sms"*/}
      {/*      label="sms"*/}
      {/*      checked={data.details.sms}*/}
      {/*      handleChange={(key, value) => console.log('sms', value)}*/}
      {/*    />*/}
      {/*  </AgreementItemsWrapper>*/}
      {/*) : (*/}
      {/*  <AgreementNameTitle>*/}
      {/*    {translate({ key: `gdpr.agreements.details.${data.tag}` })}*/}
      {/*  </AgreementNameTitle>*/}
      {/*)}*/}
      <AgreementNameTitle isDisabled={shouldDisableAgreement(data.tag)}>
        {translate({ key: `gdpr.agreements.details.${data.tag}` })}
      </AgreementNameTitle>
      <TooltipLarge message={translate({ key: data.description, replace: getReplaceValues(data.tag, user) })}>
        <AgreementIcon icon="info" />
      </TooltipLarge>
      {!readonly ? (
        <EditStatusIcon icon="edit_filled" onClick={() => toggleTag(data.tag as string)} />
      ) : null}
    </AgreementNameCol>
  );

  const renderAgreementStatus = (data: TAgreement) => {
    const statusesForUpdate = AGREEMENTS_TO_BE_DISABLED.includes(data.tag) ? filterStatusesForUpdate([...STATUSES_FOR_UPDATE]) : STATUSES_FOR_UPDATE;

    return (
      <StatusCol isRelative>
        <DefaultIcon icon={STATUS_ICONS[data.status]} />
        <StatusTitle>
          {translate({ key: getStatusTranslationKeyFromGDPRSchema(GDPRSchema, data.status) })}
        </StatusTitle>
        {tag === data.tag && (
          <StatusList ref={statusListRef}>
            {statusesForUpdate.map(status => (
              <StatusListItem key={status} onClick={(event) => {
                event.stopPropagation();
                setTag('');
                updateAgreement(status, data.id, data.tag);
              }}>
                <StatusCol>
                  <DefaultIcon icon={STATUS_ICONS[status]} />
                  <StatusTitle>
                    {translate({ key: getStatusTranslationKeyFromGDPRSchema(GDPRSchema, status) })}
                  </StatusTitle>
                </StatusCol>
              </StatusListItem>
            ))}
          </StatusList>
        )}
      </StatusCol>
    );
  };

  const renderAgreementUpdatedDate = (data: TAgreement) => (
    <ColData>
      {data.updated_at ? new Date(data.updated_at).toLocaleString(DEFAULT_LOCALE) : ''}
    </ColData>
  );

  if (GDPRSchemaErrors?.length) {
    return (
      <>
        {renderErrors(processGDPRErrors(GDPRSchemaErrors, user))}
      </>
    );
  }

  if ((!GDPRSchemaFetchStatus || GDPRSchemaFetchStatus === 'loading') || dataFetchStatus === 'loading') {
    return <Loader />;
  }

  return (
    <>
      <GDPRTitle>{translate({ key: 'common.gdpr_title' })}</GDPRTitle>
      <GDPRTable
        dataStatus={dataFetchStatus as string}
        data={agreements as never[]}
        noResultsText={noResultsText || translate({ key: 'common.no_results' })}
        showHeadline={true}
        columns={[
          {
            name: 'name',
            title: translate({ key: 'common.name' }),
            onRender: (data) => renderAgreementName(data, user),
            styles: {
              flex: 2,
            }
          },
          {
            name: 'status',
            title: translate({ key: 'common.status' }),
            onRender: renderAgreementStatus,
            styles: {
              flex: 1
            }
          },
          {
            name: 'updated_at',
            title: translate({ key: 'common.updated_at' }),
            onRender: renderAgreementUpdatedDate,
            styles: {
              flex: 1,
            }
          },
        ]}
      />
      <GDPRControlsRow>
        <GDPRControlsButton size="s" onClick={getAgreements} disabled={readonly}>
          <ButtonInner>
            {translate({ key: 'customer.gdpr.refresh' })}
            <GDPRControlsIcon icon="eye" />
          </ButtonInner>
        </GDPRControlsButton>
        <GDPRControlsButton size="s" onClick={sendEmail} disabled={readonly || !isAnyAgreementChecked(agreements)}>
          <ButtonInner>
            {translate({ key: 'customer.gdpr.mail' })}
            <GDPRControlsIcon icon="mail" />
          </ButtonInner>
        </GDPRControlsButton>
        <GDPRControlsButton size="s" onClick={printAgreements} disabled={readonly || !isAnyAgreementChecked(agreements)}>
          <ButtonInner>
            {translate({ key: 'customer.gdpr.download' })}
            <GDPRControlsIcon icon="download" />
          </ButtonInner>
        </GDPRControlsButton>
      </GDPRControlsRow>
    </>
  );
};

const getStatusTranslationKeyFromGDPRSchema = (schema: JSONSchemaProp, status: number) => schema ? schema.definitions?.statuses?.enum_translation_keys?.[status] : '';

export const getDescriptionTranslationKeyFromGDPRSchema = (agreements: IGDPRAgreement[], tag: string) => {
  if (agreements.length) {
    const agreement = agreements.find(agreement => getAgreementTag(agreement) === tag);
    return agreement ? agreement.properties.tag.description_translation_key : '';
  } else {
    return '';
  }
};

export const getAgreementTag = (agreement: IGDPRAgreement) => agreement.properties.tag.enum?.length ? agreement.properties.tag.enum[0] : '';

const getReplaceValues = (tag: string, user: TUserData) => {

  switch (tag) {
    case EMAIL_GDPR_AGREEMENT:
    case SMS_PHONE_GDPR_AGREEMENT:
      return [user?.broker?.name || ''];
    case MARKETING_GDPR_AGREEMENT:
      return [user?.broker?.name || ''];
    case DATA_PROCESSING_GDPR_AGREEMENT:
      return [user?.broker?.name || '', user?.props.name || ''];
    default:
      return [];
  }

};

const isAnyAgreementChecked = (agreements: TAgreement[]) => {
  let checked = false;

  agreements.forEach(agreement => {
    if (agreement.checked) {
      checked = true;
    }
  });

  return checked;
};


const processGDPRErrors = (errors: TError[], user: TUserData) => errors.map(item => {
  if (item.code === 'GDPR00209') {
    item.replace = [user?.broker?.tag || ''];
  }

  return item;
});
