import {
  JSONSchema,
  TCustomer,
  TCustomerAdd,
  TCustomerType,
  TParticipantAdd,
  TQuoteParticipant,
  TQuotePolicyHolder
} from 'models';
import { AxiosError } from 'axios';
import { isIddValid, translate } from 'utils';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import { DEFAULT_RESIDENT_COUNTRY_CODE } from 'config/api';
import React, { useEffect, useState } from 'react';
import IDD, { setCustomerData } from 'app/Components/IDD';
import { IDDWrapperStyled } from './styles';

export type THandleAddParticipant = (participant: TQuoteParticipant, onError: (error?: AxiosError) => void, onSuccess?: (revisionId: string) => void, shouldUpdateParticipant?: boolean) => void;
export type THandleAddPolicyHolder = (policyHolder: TQuotePolicyHolder) => void;
export type THandleUpdateParticipantData = (participant: TQuoteParticipant) => void;
export type THandleCheckPeselUnique = (pesel: string) => boolean;

interface IIDDWrapper {
  customer: TCustomer,
  quoteId: string,
  quoteCreatedAt: string,
  handleUpdateIDDRequiredStatus: ({ isIDDValid, isIDDExist }: { isIDDValid: boolean, isIDDExist: boolean }) => void,
  isIDDNotValid?: boolean,
  updateHighlighted?: (highlighted: boolean) => void,
  readOnly?: boolean,
}

export const applyDefaultParticipantValues = (formData: Record<string, any>, schemaProps: JSONSchema) => {
  const touchedFormData = formData;

  Object.keys(touchedFormData).forEach(key => {
    if (typeof touchedFormData[key] === 'undefined' && schemaProps[key] && typeof schemaProps[key].default_value !== 'undefined') {
      touchedFormData[key] = schemaProps[key].default_value;
    }
  });

  return touchedFormData;
};

export const setOPTAdditionalData = (userData: TQuoteParticipant['customer'], type: TCustomerType) => {
  switch (type) {
    case 'individual':
      return [{
        type: 'primary',
        label: `${userData.props.resident ? translate({ key: 'customer.pesel' }) : translate({ key: 'customer.idCode' })}:`,
        value: userData.props.resident ? userData.props.pesel : userData.props.idCode,
      }];
    case 'company':
      const companyData = [];

      if (userData.props.regon) {
        companyData.push({
          type: 'primary',
          label: `${translate({ key: 'customer.regon' })}:`,
          value: userData.props.regon,
        });
      }

      if (userData.props.nip) {
        companyData.push({
          type: 'primary',
          label: `${translate({ key: 'customer.nip' })}:`,
          value: userData.props.nip || '',
        });
      }

      if (userData.props.companyType === SOLE_TRADER_CUSTOMER_TYPE && (userData.props.firstName && userData.props.lastName)) {
        companyData.push({
          type: 'primary',
          label: `${userData.props.resident === false ? translate({ key: 'customer.idCode' }) : translate({ key: 'customer.pesel' })}:`,
          value: userData.props.resident === false ? userData.props.idCode : userData.props.pesel,
        });
        companyData.push({
          type: 'primary',
          label: `${translate({ key: 'quote_motor.participants.customer.full_name' })}:`,
          value: `${userData.props.firstName} ${userData.props.lastName}`,
        });
      }
      return companyData;
    default:
      return [];
  }
};

export const updatePolicyHolderType = (key: string, value: string, handleChangeMultiple: (data: Partial<TCustomerAdd | TParticipantAdd>) => void) => {
  if (value === SOLE_TRADER_CUSTOMER_TYPE) {
    handleChangeMultiple({
      type: 'company',
      company_type: SOLE_TRADER_CUSTOMER_TYPE,
      resident: true,
    });
  } else {
    handleChangeMultiple({
      type: value as TCustomerType,
      company_type: undefined,
      resident: true,
    });
  }
};

export const updateParticipantType = (key: string, value: string, handleChangeMultiple: (data: Partial<TCustomerAdd | TParticipantAdd>) => void) => {
  if (value === SOLE_TRADER_CUSTOMER_TYPE) {
    handleChangeMultiple({
      customerType: 'company',
      companyType: SOLE_TRADER_CUSTOMER_TYPE,
      resident: true,
    });
  } else {
    handleChangeMultiple({
      customerType: value as TCustomerType,
      companyType: undefined,
      resident: true,
    });
  }
};

export const prepareParticipantDataBeforeSend = (participantFormData: TParticipantAdd) => {
  let participantProps: Partial<TParticipantAdd> = {};

  switch (participantFormData.customerType) {
    case 'individual':
      participantProps = {
        firstName: participantFormData.firstName,
        lastName: participantFormData.lastName,
        familyName: participantFormData.familyName,
        birthDate: participantFormData.birthDate,
        gender: participantFormData.gender,
        companyForm: undefined,
        resident: typeof participantFormData.resident === 'boolean' ? participantFormData.resident : true,
        countryCode: participantFormData.countryCode || (participantFormData.resident ? DEFAULT_RESIDENT_COUNTRY_CODE : '')
      };

      if (typeof participantFormData.resident === 'boolean' && !participantFormData.resident) {
        participantProps.idCode = participantFormData.idCode;
      } else {
        participantProps.pesel = participantFormData.pesel;
      }

      break;
    case 'company':
      participantProps = {
        companyType: participantFormData.companyType,
      };

      if (participantFormData.companyType === SOLE_TRADER_CUSTOMER_TYPE) {
        participantProps.firstName = participantFormData.firstName;
        participantProps.lastName = participantFormData.lastName;
        participantProps.familyName = participantFormData.familyName;
        participantProps.pesel = participantFormData.pesel;
        participantProps.birthDate = participantFormData.birthDate;
        participantProps.gender = participantFormData.gender;
        participantProps.name = participantFormData.name || undefined;
        participantProps.regon = participantFormData.regon || undefined;
        participantProps.nip = participantFormData.nip || undefined;
      } else {
        participantProps.companyForm = participantFormData.companyForm;
        participantProps.name = participantFormData.name;
        participantProps.regon = participantFormData.regon;
        participantProps.nip = participantFormData.nip || undefined;
        participantProps.pkdCode = participantFormData.pkdCode || undefined;
        participantProps.pkdDescription = participantFormData.pkdDescription || undefined;
      }
  }

  participantProps.draft = undefined;

  return participantProps;
};

export const IDDWrapper = ({ customer, quoteId, quoteCreatedAt, handleUpdateIDDRequiredStatus, isIDDNotValid, updateHighlighted, readOnly }: IIDDWrapper) => {
  const [isVisible, updateIsVisible] = useState(false);

  useEffect(() => {
    updateIsVisible(false);
  }, [customer.id]);

  return (
    <IDDWrapperStyled>
      <IDD
        customerData={setCustomerData(customer)}
        quoteId={quoteId}
        initialIDDState="to_check_if_exist"
        hidePolicyNumber={true}
        showOnly={isVisible}
        handleIddCancel={() => updateIsVisible(false)}
        handleIddSave={() => updateIsVisible(false)}
        handleIDDUpdate={(idd) => handleUpdateIDDRequiredStatus(isIddValid(idd, quoteCreatedAt))}
        updateHighlighted={updateHighlighted}
        highlighted={isIDDNotValid}
        readOnly={readOnly}
      />
    </IDDWrapperStyled>
  );
};

export const isPolicyHolder18yearsOld = (birthDate: string) => {
  const birthDateYear = new Date(birthDate).getFullYear();
  const currentYear = new Date().getFullYear();

  return (currentYear - birthDateYear) >= 18;
};
